<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
         Добавить нового пользователя
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          v-slot="{invalid}"
      >
        <!-- Форма -->
        <b-form
          class="p-2"
          @submit.prevent
        >

          <BlInput
              name="email"
              label="Email"
              rules="required|email"
              placeholder="email@gmail.com"
              input-type="text"
              v-model="email"
          ></BlInput>
          <BlInput
              name="firstName"
              label="Имя"
              rules="required|alpha_spaces"
              placeholder="Иван"
              input-type="text"
              v-model="first_name"
          ></BlInput>
          <BlInput
              name="lastName"
              label="Фамилия"
              rules="required|alpha_spaces"
              placeholder="Иванов"
              input-type="text"
              v-model="last_name"
          ></BlInput>
          <BlInput
              name="middleName"
              label="Отчество"
              placeholder="Иванович"
              input-type="text"
              v-model="middle_name"
          ></BlInput>
          <BlInput
              name="phone"
              label="Телефон"
              rules="required|numeric"
              placeholder="37544 766 66 68"
              input-type="text"
              v-model="phone"
          ></BlInput>
          <BlInput
              name="companyName"
              label="Название организации, ИП"
              rules="required"
              placeholder="ООО 'Освещение'"
              input-type="text"
              v-model="company_name"
          ></BlInput>
          <BlInput
              name="companyAddress"
              label="Адрес организации"
              rules="required"
              input-type="text"
              placeholder="Беларусь, г. Минск, ул Аранская, 13 ком 9-10"
              v-model="company_address"
          ></BlInput>
          <BlInput
              name="position"
              label="Ваша должность"
              rules="required"
              placeholder="Менеджер по продажам"
              input-type="text"
              v-model="position"
          ></BlInput>
          <BlInput
              name="password"
              label="Пароль"
              rules="required|min:8"
              placeholder=".........."
              input-type="password"
              v-model="password"
          ></BlInput>
<!--          <BlInput name="confirmPassword"-->
<!--                   label="Пароль"-->
<!--                   rules="required|confirmed:password"-->
<!--                   placeholder=".........."-->
<!--                   input-type="password"-->
<!--                   v-model="confirm_password">-->
<!--          </BlInput>-->

          <!-- Кнопки -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="register()"
              :disabled="invalid"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BlInput from "@/components/controls/BlInput";
import '@/libs/custom-validate'
import api from "@/api";
import utils from "@/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BlInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    register() {
      const self = this;
      api.users
          .addClient(
              this.email,
              this.phone,
              this.company_name,
              this.company_address,
              this.first_name,
              this.last_name,
              this.middle_name,
              this.position,
              this.password,
          )
          .then((response) => {
            const data = response.data;
            if (data && !data.error) {
              utils.notificate(self, data.message);
              this.$emit("refetch-data");
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
  },
  directives: {
    Ripple,
  },
  props: ['isActive'],
  data() {
    return {
        first_name: "",
        last_name: "",
        middle_name: "",
        company_address: "",
        position: "",
        phone: "",
        email: "",
        company_name: "",
        password: "",
        confirm_password: "",
        manager: "",
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
