<template>
  <div>
    <!-- Поиск клиентов-->
    <b-card no-body class="p-2">
      <h5>Поиск клиентов</h5>
      <b-row>
        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
                v-model="searchModel"
                @input="searchFromData"
                class="d-inline-block mr-1"
                placeholder="Поиск по фамилии, имени, организации"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="dataLoading" rounded="sm">

      <!-- Новый клиент -->
      <modal-new-client
          :isActive="this.isAddNewUserSidebarActive"
          @refetch-data="loadClients(pagination.perPage, offset, table.sort.desc, table.sort.typeSort);"
      />

      <!-- Враппер таблицы -->
      <b-card no-body class="mb-0">

        <!-- Шапка таблицы -->
        <div class="m-2">
          <b-row class="justify-content-between">

            <!-- Показывать... -->
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать</label>
              <v-select
                  v-model="pagination.perPage"
                  :options="pagination.options"
                  :clearable="false"
                  @input="loadClients(pagination.perPage, offset, table.sort.desc, table.sort.typeSort);"
                  class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!--  Новый клиент  -->
            <b-button
                variant="primary"
                class="mr-1 col-12 col-md-3 col-lg-2"
                @click="isAddNewUserSidebarActive = true"
            >
              <span class="text-nowrap">+ Новый клиент</span>
            </b-button>
          </b-row>
        </div>

        <!--  Таблица  -->
        <b-table
            @sort-changed="sorting"
            ref="refUserListTable"
            class="position-relative"
            :items="table.clients"
            :per-page="pagination.perPage"
            responsive
            small
            :fields="table.tableColumns"
            primary-key="id"
            show-empty
            empty-text="Нет подходящих результатов"
        >
          <!-- Название компании -->
          <template #cell(company_name)="data">
            <b-media vertical-align="center">
              {{ checkBlank(data.item.company_name) }}
            </b-media>
          </template>

          <!-- Дата регистрации -->
          <template #cell(date_joined)="data">
            <b-media vertical-align="center">
              <!--              {{ new Date(data.item.date_joined) }}-->
              <span>{{ data.item.date_joined | moment("DD.MM.YYYY HH:mm") }}</span>
            </b-media>
          </template>

          <!-- Статус клиента -->
          <template #cell(status)="data">
            <b-badge
                pill
                :variant="`light-${resolveClientVariant(data.item.is_confirmed, data.item.is_active)}`"
                class="text-capitalize"
            >
              {{ resolveClientLabel(data.item.is_confirmed, data.item.is_active) }}
            </b-badge>
          </template>

          <!-- Имя клиента -->
          <template #cell(last_name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="data.item.avatar"

                    :variant="`light-${resolveClientVariant(data.item.role)}`"
                    :to="{
                    name: 'client-page',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <b-link
                  :to="{ name: 'client-page', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ checkBlank(data.item.fullName) }}
              </b-link>
            </b-media>
          </template>

          <!-- Имя менеджера -->
          <template #cell(manager)="data">
            <b-media vertical-align="center" v-if="data.item.manager">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="data.item.manager.avatar"

                    :variant="`light-${resolveClientVariant(data.item.manager.role)}`"
                    :to="{
                    name: 'client-page',
                    params: { id: data.item.manager.id },
                  }"
                />
              </template>
              <b-link
                  :to="{ name: 'client-page', params: { id: data.item.manager.id } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ checkBlank(data.item.manager.fullName) }}
              </b-link>
            </b-media>
          </template>

          <!-- Уровень цены -->
          <template #cell(price_level)="data">
            <b-media vertical-align="center">
              {{ utils.getPriceLevelNameByCode(data.item.price_level) }}
            </b-media>
          </template>

          <!-- Управление -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                dropleft
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                  :to="{ name: 'client-page', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">Редактировать</span>
              </b-dropdown-item>
              <b-dropdown-item @click="activateClient(data.item.id)"
              >
                <feather-icon icon="PowerIcon"/>
                <span class="align-middle ml-50">Активировать</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deactivateClient(data.item.id)"
              >
                <feather-icon icon="PowerIcon"/>
                <span class="align-middle ml-50">Деактивировать</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteClient(data.item.id)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>

        <!-- Карточка пагинации -->
        <div class="mx-2 mb-2">
          <b-row>

            <!--  Показаны xx из xx результатов -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
              >Показаны {{ offset + 1 }} - {{ offset + (table.clients.length) }} из
                {{ pagination.rows }} результатов</span
              >
            </b-col>

            <!-- Паджинация -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  :per-page="pagination.perPage"
                  v-model="pagination.currentPage"
                  :total-rows="pagination.rows"
                  @input="changePage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ModalNewClient from '@/components/views/clients/ModalNewClient'
import utils from '@/utils'


import api from '@/api'

export default {
  components: {
    ModalNewClient,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
  data() {
    return {
      utils,
      pagination: {
        perPage: 10,
        currentPage: 1,
        options: [10, 25, 50, 100],
        rows: 100,
      },
      table: {
        clients: [],
        tableColumns: [
          {key: 'company_name', label: 'Название компании', sortable: true},
          {key: 'date_joined', label: 'Дата регистрации', sortable: true},
          {key: 'status', label: 'Статус клиента', sortable: true},
          {key: 'last_name', label: 'Имя клиента', sortable: true},
          {key: 'manager', label: 'Имя менеджера', sortable: false},
          {key: 'price_level', label: 'Уровень цены', sortable: true},
          {key: 'actions', label: 'Упр'},
        ],
        sort: {
          typeSort: 'id',
          desc: ''
        }
      },
      dataLoading: true,
      searchModel: '',
      isAddNewUserSidebarActive: false
    };
  },
  mounted() {
    this.loadClients(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort);
  },

  methods: {
    // Методы для таблицы
    loadClients(limit, offset, desc, sortType) {
      const self = this;
      self.dataLoading = true;
      api.users
          .getAllClients(limit, offset, desc, sortType)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.table.clients = data.result.items;
              self.pagination.rows = data.result.total_count;
              for (let client of self.table.clients) {
                client.fullName = `${client.last_name} ${client.first_name} ${client.middle_name}`;
                if (client.manager !== null) {
                  client.manager.fullName = `${client.manager.last_name} ${client.manager.first_name} ${client.manager.middle_name}`;
                }
              }
              self.dataLoading = false;
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      this.isAddNewUserSidebarActive = false;
    },
    sorting(ctx) {
      if (ctx.sortDesc == true) {
        this.table.sort.desc = '-'
      } else
        this.table.sort.desc = ''
      this.table.sort.typeSort = ctx.sortBy
      const self = this;
      self.dataLoading = true;
      this.searchModel = ''
      this.loadClients(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort)
    },
    changePage() {
      this.searchModel = ''
      this.loadClients(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort)
    },
    checkBlank(value) {

      if ((!value) || (value.length < 1) || (value.trim() === '')) {
        return 'Не указано'
      }
      return value;
    },

    // Методы dropDown меню
    deactivateClient(id) {
      const self = this;
      self.dataLoading = true;
      api.users
          .deactivateClient(id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.table.clients = data.result;
              self.dataLoading = false;
              utils.notificate(self, data.message);
              this.loadClients(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    activateClient(id) {
      const self = this;
      self.dataLoading = true;
      api.users
          .activateClient(id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.table.clients = data.result;
              self.dataLoading = false;
              utils.notificate(self, data.message);
              this.loadClients(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort);

            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    deleteClient(id) {
      console.log('Delete client', id)
      const self = this;
      api.users
          .remove(id)
          .then((response) => {

            const data = response.data;
            if (data && data.message) {
              utils.notificate(self, data.message);
            }
            self.loadClients(self.pagination.perPage, self.offset, self.table.sort.desc, self.table.sort.typeSort);
            // if (!data.error) {
            //   this.$router.push('/clients')
            // }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    // Отрисовка статуса клиента
    resolveClientVariant(confirmed, active) {
      if (confirmed === true) return 'success'
      if (active === true) return 'warning'
      return 'danger'
    },
    resolveClientLabel(confirmed, active) {
      if (confirmed === true) return 'Активен'
      if (active === true) return 'На модерации'
      return 'Не активен'
    },

    //Поиск
    searchFromData() {
      const self = this;
      self.dataLoading = true;
      api.users
          .searchClients(this.searchModel)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.table.clients = data.result.items;
              self.pagination.rows = data.result.items.length;
              for (let client of self.table.clients) {
                client.fullName = `${client.last_name} ${client.first_name} ${client.middle_name}`;
                if (client.manager !== null) {
                  client.manager.fullName = `${client.manager.last_name} ${client.manager.first_name} ${client.manager.middle_name}`;
                }
              }
              self.dataLoading = false;
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      this.isAddNewUserSidebarActive = false;
    },

  },
  computed: {
    offset() {
      return (this.pagination.currentPage - 1) * this.pagination.perPage
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
